import { createRouter, createWebHistory } from 'vue-router';
import FormView from '../views/FormView.vue';
import TemplateBanner from '../views/Template-banner.vue';
const routes = [
{
    path: '/',
    name: 'Redeem your swag | Swagsum',
    component: FormView
  },
  {
      path: '/adobe/:slug/:url_token?',
      name: 'Redeem your swag | Swagsum',
      component: TemplateBanner
    },
  {
    path: '/:team/:slug/:url_token?',
    name: 'Redeem your swag | Swagsum',
    component: FormView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
